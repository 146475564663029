import { useEffect } from 'react';

const useJornaya = () => {
  useEffect(() => {
    window.setLeadId = function (leadId) {
      window.leadId = leadId;
    };

    const insertLeadiDScript = () => {
      if (document.getElementById('LeadiDscript_campaign')) {
        return;
      }

      const script = document.createElement('script');
      script.id = 'LeadiDscript_campaign';
      script.type = 'text/javascript';
      script.async = true;
      script.src =
        '//create.lidstatic.com/campaign/7d1cf8a3-da6c-ebac-a6e7-3f41cc5eff51.js?snippet_version=2&callback=setLeadId';

      const LeadiDscript = document.getElementById('LeadiDscript');
      LeadiDscript.parentNode.insertBefore(script, LeadiDscript);
    };

    const mainScript = document.createElement('script');
    mainScript.id = 'LeadiDscript';
    mainScript.type = 'text/javascript';
    mainScript.defer = true;
    mainScript.innerHTML = `(${insertLeadiDScript.toString()})()`;

    document.body.appendChild(mainScript);

    const noScriptImg = document.createElement('img');
    noScriptImg.src =
      '//create.leadid.com/noscript.gif?lac=29705C9D-232A-8A19-97CA-C832491B96A7&lck=7d1cf8a3-da6c-ebac-a6e7-3f41cc5eff51&snippet_version=2';
    document.body.appendChild(noScriptImg);

    return () => {
      document.body.removeChild(mainScript);
      document.body.removeChild(noScriptImg);
      const scriptCampaign = document.getElementById('LeadiDscript_campaign');
      if (scriptCampaign) {
        scriptCampaign.parentNode.removeChild(scriptCampaign);
      }

      delete window.setLeadId;
    };
  }, []);

  return null;
};

export default useJornaya;
