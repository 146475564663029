import {
  CONSECUTIVE_PHONE_NUMBER_ERROR_MESSAGE,
  EMAIL_DOMAIN_ERROR_MESSAGE,
  EMAIL_ERROR_MESSAGE,
  EMAIL_REGEX,
  NETWORK_ERROR,
  ZIP_CODE_ERROR_MESSAGE,
} from '../constants';

const tlds = require('tld-list');

export const getErrorMessage = (errors) => {
  if (Object.keys(errors).length > 1)
    return 'Please Fill out the required fields';

  return Object.values(errors)[0]?.message;
};

export const isZipCodeValid = async (zipCode) => {
  try {
    if (!zipCode) return { zip: zipCode, error: ZIP_CODE_ERROR_MESSAGE };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/zip/lookup?zip=${zipCode}`
    );

    if (!response.ok) {
      throw new Error(NETWORK_ERROR);
    }

    const data = await response.json();
    const { city, state, zip } = data;

    if (city && state) {
      return {
        zip,
        city,
        state,
        error: '',
      };
    } else {
      return { error: ZIP_CODE_ERROR_MESSAGE, zip };
    }
  } catch (error) {
    console.error(error);
    return { zip: zipCode, error: NETWORK_ERROR };
  }
};

export const onlyNumbersInput = (e) => {
  const nonNumericCharacters = /[^0-9]+/g;
  e.target.value = e.target.value.replace(nonNumericCharacters, '');
};

export const onlyLettersInput = (e) => {
  const lettersAndSpacesOnly = /[^\p{L}\s]+/gu;
  e.target.value = e.target.value.replace(lettersAndSpacesOnly, '');
};

export const validateEmail = (email) => {
  const domain = email.split('.').pop();

  if (!EMAIL_REGEX.test(email)) {
    return EMAIL_ERROR_MESSAGE;
  }

  if (!tlds.includes(domain)) {
    return EMAIL_DOMAIN_ERROR_MESSAGE;
  }

  return true;
};

export const validatePhoneNumber = (phoneNumber) => {
  const cleanedNumber = phoneNumber.replace(/[-\s()]/g, '');

  const allDigitsAreEqual = (number) =>
    number.split('').every((digit) => digit === number[0]);

  if (
    (cleanedNumber.length === 7 && allDigitsAreEqual(cleanedNumber)) ||
    (cleanedNumber.length === 10 && allDigitsAreEqual(cleanedNumber.slice(3)))
  )
    return CONSECUTIVE_PHONE_NUMBER_ERROR_MESSAGE;

  return true;
};
