import React, { createContext, useContext, useEffect, useState } from 'react';

const AddressContext = createContext();
export const useAddressContext = () => useContext(AddressContext);
export const AddressProvider = ({ children }) => {
  const [addressData, setAddressData] = useState({
    address2: '',
    locality: '',
    state: '',
    zip_code: '',
    country: '',
  });

  useEffect(() => {
    const storedAddressData = localStorage.getItem('addressData');
    if (storedAddressData) {
      setAddressData(JSON.parse(storedAddressData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('addressData', JSON.stringify(addressData));
  }, [addressData]);

  return (
    <AddressContext.Provider value={{ addressData, setAddressData }}>
      {children}
    </AddressContext.Provider>
  );
};
