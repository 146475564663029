import { useEffect } from 'react';

const useAnura = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const AffiliateID = urlParams.get('AffiliateID');
    const S1 = urlParams.get('S1');
    const PublisherID = urlParams.get('publisher_id');
    const SubId = urlParams.get('subid');

    let anurapub = 1;
    let anurasub = '';

    if (AffiliateID) {
      process.env.REACT_APP_USER_MAP.split(',').map((elemMap) => {
        const elemMapItem = elemMap.split('=');

        if (parseInt(elemMapItem[0]) === parseInt(AffiliateID)) {
          anurapub = parseInt(elemMapItem[1]);
        }
      });
    } else {
      if (PublisherID) {
        anurapub = PublisherID;
      }
    }

    if (S1) {
      anurasub = S1;
    }

    if (SubId) {
      anurasub = SubId;
    }

    sessionStorage.setItem('anura_pub', anurapub);
    sessionStorage.setItem('anura_sub', anurasub);

    fetch(
      `${process.env.REACT_APP_API_URL}/landingpage/validation/anura/${anurapub}`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((response) => {
        sessionStorage.setItem('activated_response', response.data);

        if (response.data === 'on') {
          const anurapub = sessionStorage.getItem('anura_pub');
          const anurasub = sessionStorage.getItem('anura_sub');

          const request = {
            instance: 3171636640,
            campaign: anurasub,
            source: anurapub,
            callback: 'optionalCallbackFunction',
          };

          const params = [];
          for (const x in request)
            params.push(`${x}=${encodeURIComponent(request[x])}`);
          params.push(Math.floor(1e12 * Math.random() + 1));

          const anura = document.createElement('script');
          anura.setAttribute('id', 'anurascript');
          anura.async = false;
          anura.type = 'text/javascript';
          anura.src = `https://script.anura.io/request.js?${params.join('&')}`;

          const [script] = document.getElementsByTagName('script');
          script.parentNode.insertBefore(anura, script);

          window.optionalCallbackFunction = (response) => {
            const anura_id_response = response.getId();
            sessionStorage.setItem('anura_final_response', anura_id_response);
          };
        }
      })
      .catch((error) => console.error('Error getting the data', error));

    return () => {
      const anuraScript = document.getElementById('anurascript');
      if (anuraScript) {
        anuraScript.parentNode.removeChild(anuraScript);
      }

      delete window.optionalCallbackFunction;
    };
  }, []);
  return null;
};

export default useAnura;
