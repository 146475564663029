import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import Slider from 'react-slick';

function Testimonials() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="testimonials" className="testiArea py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="text-green font-bold m-0 text-center">
              Premier Partner Testimonials
            </h3>
          </div>
        </div>
        <div className="row">
          <Slider {...settings}>
            <div>
              <p>
                “I dont know where I would be had it not been for Stay Franks
                program. When everyone was saying no, sorry, can not help; Stay
                Frank. said no problem. The staff is phenomenal! They answered
                all of my questions. Thank you!”
              </p>
              <h3>- Angie M., Fort Worth, TX</h3>
            </div>
            <div>
              <p>
                “I am doing much better now that I found this opportunity to be
                able to get ahead in my situation and still stay in my home.
                StayFrank. was very helpful and took the time to walk me through
                the process. I am grateful for the opportunity to participate
                with the team at Stay Frank. Thank you for everything.”
              </p>
              <h3>- Steve F., Phoenix, AZ</h3>
            </div>
            <div>
              <p>
                “From the beginning StayFrank has been professional in carrying
                out this process. Answered all of my questions and ensured
                everything from beginning to end to go as I expected.”
              </p>
              <h3>- Theodore W.</h3>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
