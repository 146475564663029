import './topBarAndFooter.css';

import instantCashLogo from '../../assets/img/logo.png';

function TopBarAndFooter({ className = '' }) {
  return (
    <header className={`p-0 m-0 ${className}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-5 col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <a href="/">
              <img src={instantCashLogo} alt="logo" />
            </a>
          </div>
          <div className="col-7 col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <a href="#form" className="ctaGreen ctaSml float-right">
              Get My Instant Cash Offer
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default TopBarAndFooter;
