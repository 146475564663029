import { useEffect, useState } from 'react';

function useExternalScript(scriptUrl, variableName, variableValue) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptUrl;
        script.async = true;

        window[variableName] = variableValue;

        document.head.appendChild(script);

        script.onload = () => {
            setIsLoaded(true);
        };

        return () => {
            document.head.removeChild(script);
        };
    }, [scriptUrl, variableName, variableValue]);

    return isLoaded;
}

export default useExternalScript;
