import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';
import React from 'react';
function FaqArea() {
  return (
    <div>
      <section className="faqArea py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="text-green titleSml font-bold">FAQs</h4>
              <h3 className="text-blue font-bold mt-0 mb-5 mx-auto">
                About A Home Sale Leaseback.
              </h3>
              <p className="text-desc font-reg pt-3 pb-5">
                Because Instant Cash Offers &apos;s home sale-leaseback program
                is truly unlike traditional lending models, it &apos;s no
                surprise you have questions! Get your answers, instantly.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="content">
                <MDBContainer className="">
                  <MDBAccordion alwaysOpen initialActive={1}>
                    <MDBAccordionItem
                      collapseId={1}
                      headerTitle="What is the Sell Now & Move Later home offer?"
                    >
                      <p>
                        The Sell Now & Move Later home offer allows you to sell
                        your home to us for an instant cash offer while giving
                        you the flexibility to choose a future move-out date
                        that works best for you.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={2}
                      headerTitle="Do I have to move out of my house?"
                    >
                      <p>
                        We&apos;re going to give it to you Frank: Ultimately,
                        yes, you have to move out. However, the benefit of
                        leasing your home as a tenant is prolonging the “when.”
                        Most Instant Cash Offers customers stay anywhere from a
                        few months to up to 5 years. The average customer signs
                        a 2-year lease. We know it can be hard to say goodbye,
                        however, the point of our home sale-leaseback program is
                        to help you transition into the next chapter of your
                        life. You are selling your house to start over in a
                        better financial position— and we&apos;re here to help
                        you ease into your new home with less debt and stress.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={3}
                      headerTitle="Is this option available for all types of homes?"
                    >
                      <p>
                        No this program is only available to for single family
                        homes, not Condo&apos;s, Townhomes, Manufactured, or
                        mobile homes.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={4}
                      headerTitle="Can I have the option to buy my house back?"
                    >
                      <p>
                        Yes, in some cases we offer homeowners the option to buy
                        their home back in the future.
                      </p>
                    </MDBAccordionItem>
                  </MDBAccordion>
                </MDBContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FaqArea;
