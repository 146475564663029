import { Controller, useFormContext } from 'react-hook-form';
import { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';

import MaskedInput from 'react-text-mask';
import { handleKeyDown } from '../../lib/utils';

const MaskedInputWithRef = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.inputElement.focus();
    },
  }));

  return <MaskedInput ref={inputRef} {...props} />;
});

MaskedInputWithRef.displayName = 'MaskedInputWithRef';

function CustomPhoneInput({
  name,
  mask,
  validation = {},
  placeholder = 'Phone',
  onInputChange,
  prePopValue,
  ...props
}) {
  const { control, setValue, formState, clearErrors } = useFormContext();
  const { errors } = formState;
  const inputRef = useRef();

  useEffect(() => {
    // Check if the phone number is prepopulated
    if (prePopValue) {
      const filteredValue = prePopValue.replace(/[^0-9]/g, '');

      setValue(name, filteredValue);
    }
  }, [prePopValue, setValue, name]);

  const handleOnChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');

    setValue(name, value);
    onInputChange && onInputChange({ [name]: value });

    if (errors[name]) {
      clearErrors(name);
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          ...validation,
          validate: {
            validLength: (value) => 
              value.length === 10 || "Please check Phone Number formatting (e.g., 9999999999)",
            validStartingDigit: (value) =>
              (value.charAt(0) !== '0' && value.charAt(0) !== '1') || "Please don't start Phone Number with 0 or 1",
          },
        }}
        render={({ field, ref }) => (
          <MaskedInputWithRef
            {...props}
            {...field}
            onKeyDown={handleKeyDown}
            ref={ref}
            mask={mask}
            placeholder={placeholder}
            className={` form-control text-grey ${
              errors[name] && 'form-step__input-form-control text-grey error'
            }`}
            onChange={handleOnChange}
          />
        )}
      />
      {errors[name] && <span className="error-message">{errors[name].message}</span>}
    </>
  );
}

export default CustomPhoneInput;
