import React from 'react';
import work1 from '../../../assets/img/work-1.jpg';
import work2 from '../../../assets/img/work-2.jpg';
import work3 from '../../../assets/img/work-3.jpg';
import work4 from '../../../assets/img/work-4.jpg';
import work5 from '../../../assets/img/work-5.jpg';
import work6 from '../../../assets/img/work-6.jpg';
import work7 from '../../../assets/img/work-7.jpg';
function WorkArea() {
  return (
    <div>
      <section className="workArea py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <h4 className="text-green titleSml font-bold pb-5">
                How it works?
              </h4>
            </div>
          </div>
          <div className="row mb-5 pb-5">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="picBox boxShadow text-center mx-auto">
                <div className="num bg-green">
                  <span className="text-white font-bold">1</span>
                </div>
                <img src={work1} alt="" />
                <p className="font-bold text-blue">
                  Submit your information to <br />
                  see if you home qualifies.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="picBox boxShadow text-center mx-auto">
                <div className="num bg-blue">
                  <span className="text-white font-bold">2</span>
                </div>
                <img src={work2} alt="" />
                <p className="font-bold text-blue">
                  Discovery call with one of our <br />
                  specialists to discuss your goals.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="picBox boxShadow text-center mx-auto">
                <div className="num bg-green">
                  <span className="text-white font-bold">3</span>
                </div>
                <img src={work3} alt="" />
                <p className="font-bold text-blue">
                  Submit photos of your <br />
                  home to get an initial offer.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="picBox boxShadow text-center mx-auto">
                <div className="num bg-blue">
                  <span className="text-white font-bold">4</span>
                </div>
                <img src={work4} alt="" />
                <p className="font-bold text-blue">
                  Approve the offer and <br />
                  sign the agreement.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="picBox boxShadow text-center mx-auto">
                <div className="num bg-green">
                  <span className="text-white font-bold">5</span>
                </div>
                <img src={work5} alt="" />
                <p className="font-bold text-blue">
                  Schedule Home walk through.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="picBox boxShadow text-center mx-auto">
                <div className="num bg-blue">
                  <span className="text-white font-bold">6</span>
                </div>
                <img src={work6} alt="" />
                <p className="font-bold text-blue">
                  Sign closing documents <br />
                  with the title company.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="picBox boxShadow text-center mx-auto">
                <div className="num bg-green">
                  <span className="text-white font-bold">7</span>
                </div>
                <img src={work7} alt="" />
                <p className="font-bold text-blue">
                  Receive your funds in <br />
                  as little as 14 days.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-0 pb-5">
              <a href="#form" className="ctaGreen">
                Get My Instant Cash Offer
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WorkArea;
