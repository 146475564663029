import React from 'react';
import home from '../../../assets/img/home.png';
import key from '../../../assets/img/key.png';
function ProgramArea() {
  return (
    <div>
      <section className="programArea py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center mb-5">
              <h3 className="text-white font-bold">Our Programs</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
              <div className="whiteBox">
                <img src={home} alt="" />
                <h4 className="font-med my-3">Sell Now</h4>
                <p>
                  Unlock your home equity and move with a Hassle Free offer.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
              <div className="whiteBox">
                <img src={key} alt="" />
                <h4 className="font-med my-3">Sell Now, Move Later</h4>
                <p>
                  Sell your home for cash and rent it back for up to 5 years.
                  Get the cash you need today without having to move.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center pt-5 mt-5 pb-5 mb-3">
              <a href="#form" className="ctaGreen">
                Get My Instant Cash Offer
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProgramArea;
