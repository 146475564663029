import './customSubmit.css';
import PartnerList from '../../Templates/PartnerList';
import PrivacyPolicy from '../../Templates/PrivacyPolicy';
import TermOfUse from '../../Templates/TermOfUse';

function CustomSubmit() {
  return (
    <div className="custom-submit__container">
      <div>
        <label>
          <label className="custom-submit__consent-text">
            By clicking the button below, you acknowledge, consent, and agree to
            our terms at the bottom of this page.
          </label>
          <button type="submit" className="custom-submit__btn">
            SEE MY INSTANT CASH OFFER
          </button>
          <label className="custom-submit__consent-text">
            {
              ' By clicking SEE MY INSTANT CASH OFFER you electronically agree to share your information with '
            }
            <PartnerList/>
            {
              ' and those acting on its behalf and provide your prior "written" consent for those partners (whose names you have accessed and read) to contact you (including through automated means; autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is currently listed on any state, federal or corporate Do Not Call list and you agree to our '
            }
            <a className="custom-submit__button-as-link">
              <TermOfUse/>
            </a>
            {' and '}
            <a className="custom-submit__button-as-link">
              <PrivacyPolicy/>
            </a>
            {
              '. You agree that we can share your personal data with third parties, such as our lending partners, service providers, and other affiliates, and that we can use this data for marketing and analytics, and to make your experience easier. Your consent is not a condition of accessing our services, as you may email info@rgrmarketing.com'
            }
          </label>
        </label>
        <label
            id="leadid_tcpa_disclosure_label"
            htmlFor="leadid_tcpa_disclosure"
            style={{display: 'none'}}
        >
          <input type="hidden" id="leadid_tcpa_disclosure"/>
          <label className="custom-submit__consent-text">
            By clicking the button below, you acknowledge, consent, and agree to
            our terms at the bottom of this page.
          </label>
          <label className="custom-submit__consent-text">
            {
              ' By clicking SEE MY INSTANT CASH OFFER you electronically agree to share your information with '
            }
            <PartnerList/>
            {
              ' and those acting on its behalf and provide your prior "written" consent for those partners (whose names you have accessed and read) to contact you (including through automated means; autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is currently listed on any state, federal or corporate Do Not Call list and you agree to our '
            }
            <a className="custom-submit__button-as-link">
              <TermOfUse/>
            </a>
            {' and '}
            <a className="custom-submit__button-as-link">
              <PrivacyPolicy/>
            </a>
            {
              '. You agree that we can share your personal data with third parties, such as our lending partners, service providers, and other affiliates, and that we can use this data for marketing and analytics, and to make your experience easier. Your consent is not a condition of accessing our services, as you may email info@rgrmarketing.com'
            }
          </label>
        </label>
      </div>
    </div>
  );
}

export default CustomSubmit;
