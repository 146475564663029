export const PROPERTY_CONDITION_OPTIONS = [
  {
    id: 'property_condition_needs_work_requires_significant_repairs_or_updates',
    value: 'needs work',
    textOption: 'Needs Work',
    registerLabel: 'propertycondition',
  },
  {
    id: 'property_condition_fair_could_use_updates_and_repairs',
    value: 'fair',
    textOption: 'Fair',
    registerLabel: 'propertycondition',
  },
  {
    id: 'property_condition_good_needs_a_little_bit_of_work',
    value: 'good',
    textOption: 'Good',
    registerLabel: 'propertycondition',
  },
  {
    id: 'property_condition_excellent_updated_and_move_in_ready',
    value: 'excellent',
    textOption: 'Excellent',
    registerLabel: 'propertycondition',
  },
];
export const OCCUPANCY_OPTIONS = [
  {
    id: 'occupancy_i_live_in_the_house',
    value: 'i live in the house',
    textOption: 'I live in the house',
    registerLabel: 'occupancy',
  },
  {
    id: 'occupancy_tenants_live_in_the_house',
    value: 'tenants live in the house',
    textOption: 'Tenants live in the house',
    registerLabel: 'occupancy',
  },
  {
    id: 'occupancy_property_is_vacant',
    value: 'property is vacant',
    textOption: 'Property is Vacant',
    registerLabel: 'occupancy',
  },
  {
    id: 'occupancy_other',
    value: 'other',
    textOption: 'Other',
    registerLabel: 'occupancy',
  },
];
export const REASON_FOR_SELLING_OPTIONS = [
  {
    id: 'reason_for_selling_behind_on_mortgage_payments',
    value: 'behind on mortgage payments',
    textOption: 'Behind on Mortgage Payments',
    registerLabel: 'reasonforselling',
  },
  {
    id: 'reason_for_selling_job_income_loss',
    value: 'job income loss',
    textOption: 'Job / Income Loss',
    registerLabel: 'reasonforselling',
  },
  {
    id: 'reason_for_selling_need_to_access_cash',
    value: 'need to access cash',
    textOption: 'Need to Access Cash',
    registerLabel: 'reasonforselling',
  },
  {
    id: 'reason_for_selling_property_needs_repairs',
    value: 'property needs repairs',
    textOption: 'Property Needs Repairs',
    registerLabel: 'reasonforselling',
  },
  {
    id: 'reason_for_selling_property_downsizing_relocating',
    value: 'downsizing relocating',
    textOption: 'Downsizing / Relocating',
    registerLabel: 'reasonforselling',
  },
  {
    id: 'reason_for_selling_property_curious_to_see_house_value',
    value: 'curious to see house value',
    textOption: 'Research Home Metrics',
    registerLabel: 'reasonforselling',
  },
];
export const TIMEFRAME_OPTIONS = [
  {
    id: 'timeframe_asap',
    value: 'asap',
    textOption: 'ASAP',
    subText: 'Highest Offer, Fastest Sale!',
    registerLabel: 'timeframe',
  },
  {
    id: 'timeframe_2_3_months',
    value: '2 to 3 months',
    textOption: '2-3 Months',
    registerLabel: 'timeframe',
  },
  {
    id: 'timeframe_6_months',
    value: '6 months',
    textOption: '6 Months',
    registerLabel: 'timeframe',
  },
  {
    id: 'timeframe_i_m_in_no_rush',
    value: 'im in no rush',
    textOption: 'I Am In No Rush',
    registerLabel: 'timeframe',
  },
];
export const HOME_TYPE_OPTIONS = [
  {
    id: 'home_type_single_family_home',
    value: 'single family',
    textOption: 'Single Family Home',
    registerLabel: 'propertytype',
  },
  {
    id: 'home_type_condominium',
    value: 'condominium',
    textOption: 'Condominium',
    registerLabel: 'propertytype',
  },
  {
    id: 'home_type_mobile_manufactured_home',
    value: 'mobile or manufactured home',
    textOption: 'Mobile/Manufactured Home',
    registerLabel: 'propertytype',
  },
  {
    id: 'home_type_town_house',
    value: 'townhome',
    textOption: 'TownHouse',
    registerLabel: 'propertytype',
  },
];
export const TCPA_OPT_IN_OPTIONS = [
  {
    id: 'tcpa_opt_in_yes',
    value: 'Yes',
    textOption: 'Yes',
    registerLabel: 'tcpa_opt_in',
  },
  {
    id: 'tcpa_opt_in_no',
    value: 'No',
    textOption: 'No',
    registerLabel: 'tcpa_opt_in',
  },
];
export const HOUSE_LISTED_WITH_REALTOR_OPTIONS = [
  {
    id: 'house_listed_with_a_realtor_yes',
    value: 'yes',
    textOption: 'Yes',
    registerLabel: 'realtorlisting',
  },
  {
    id: 'house_listed_with_a_realtor_no',
    value: 'no',
    textOption: 'No',
    registerLabel: 'realtorlisting',
  },
];
export const STATES_OPTIONS = [
  { text: 'AL', value: 'AL' },
  { text: 'AK', value: 'AK' },
  { text: 'AZ', value: 'AZ' },
  { text: 'AR', value: 'AR' },
  { text: 'CA', value: 'CA' },
  { text: 'CO', value: 'CO' },
  { text: 'CT', value: 'CT' },
  { text: 'DE', value: 'DE' },
  { text: 'FL', value: 'FL' },
  { text: 'GA', value: 'GA' },
  { text: 'HI', value: 'HI' },
  { text: 'ID', value: 'ID' },
  { text: 'IL', value: 'IL' },
  { text: 'IN', value: 'IN' },
  { text: 'IA', value: 'IA' },
  { text: 'KS', value: 'KS' },
  { text: 'KY', value: 'KY' },
  { text: 'LA', value: 'LA' },
  { text: 'ME', value: 'ME' },
  { text: 'MD', value: 'MD' },
  { text: 'MA', value: 'MA' },
  { text: 'MI', value: 'MI' },
  { text: 'MN', value: 'MN' },
  { text: 'MS', value: 'MS' },
  { text: 'MO', value: 'MO' },
  { text: 'MT', value: 'MT' },
  { text: 'NE', value: 'NE' },
  { text: 'NV', value: 'NV' },
  { text: 'NH', value: 'NH' },
  { text: 'NJ', value: 'NJ' },
  { text: 'NM', value: 'NM' },
  { text: 'NY', value: 'NY' },
  { text: 'NC', value: 'NC' },
  { text: 'ND', value: 'ND' },
  { text: 'OH', value: 'OH' },
  { text: 'OK', value: 'OK' },
  { text: 'OR', value: 'OR' },
  { text: 'PA', value: 'PA' },
  { text: 'RI', value: 'RI' },
  { text: 'SC', value: 'SC' },
  { text: 'SD', value: 'SD' },
  { text: 'TN', value: 'TN' },
  { text: 'TX', value: 'TX' },
  { text: 'UT', value: 'UT' },
  { text: 'VT', value: 'VT' },
  { text: 'VA', value: 'VA' },
  { text: 'WA', value: 'WA' },
  { text: 'WV', value: 'WV' },
  { text: 'WI', value: 'WI' },
  { text: 'WY', value: 'WY' },
];
