import '../CustomComponents/CustomSubmit/customSubmit.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
require('react-bootstrap/ModalHeader');
require('bootstrap/dist/css/bootstrap.css');
function PartnerList() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    event.preventDefault();
    setShow(true);
  };
  return (
    <>
      <a className="custom-submit_button-as-link" onClick={handleShow} href="#">
        <strong>up to 4 Partners</strong>
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Instant Cash Offer Partners</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-modal__body">
            <h4>Instant Cash Offer Partners</h4>
            <ol>
              <li>AgentAssistant</li>
              <li>Agents Corner LLC</li>
              <li>Amplify My Business</li>
              <li>AP Investments LLC</li>
              <li>Assent</li>
              <li>Astoria</li>
              <li>Benchmark CI</li>
              <li>Bill Maier</li>
              <li>Billy.com</li>
              <li>Blackstone Home Buyers LLC</li>
              <li>Boss Leads</li>
              <li>Century 21 Aadvantage Gold</li>
              <li>Chris Tellez</li>
              <li>CONSTELLATION ROOF LLC</li>
              <li>Credit.com</li>
              <li>Dane Wiseman</li>
              <li>DBK Distributions</li>
              <li>Digital Treetop</li>
              <li>Economy Properties</li>
              <li>Fairway Independent Mortgage Corporation</li>
              <li>GVG Capital</li>
              <li>GVG Holdings</li>
              <li>Home Advisor</li>
              <li>Home Buyer Team LLC</li>
              <li>HomeGo</li>
              <li>HomeLight, Inc.</li>
              <li>homes.com</li>
              <li>Homevestors</li>
              <li>Ideal Agent</li>
              <li>ispeedtolead.com</li>
              <li>Imortgage.com</li>
              <li>Jeremy Olsher</li>
              <li>Jerimiah Taylor</li>
              <li>Jessica Gray</li>
              <li>Keller Williams Realty &quot;The Market Place 1&quot;</li>
              <li>Khalid Alqahtani</li>
              <li>Lead Geeks</li>
              <li>Liberty AI</li>
              <li>Listing Advocate LLC DBA</li>
              <li>Loan Depot</li>
              <li>Marina Nazari</li>
              <li>Mark Hufford</li>
              <li>Matt Daniell</li>
              <li>Max House Properties</li>
              <li>Michael Robinson</li>
              <li>MN Group</li>
              <li>Motivated Leads</li>
              <li>Movement Mortgage</li>
              <li>MyLuxuryHome.Co with Keller Williams Realty</li>
              <li>Need To Sell My Home Fast</li>
              <li>OfferClimb</li>
              <li>Ojo Labs</li>
              <li>Opcity</li>
              <li>OpCity Inc.</li>
              <li>Pacific Mortgage Center</li>
              <li>Patty Hopkins</li>
              <li>Plateau Data Services, LLC</li>
              <li>PrimeStreet</li>
              <li>Quinstreet</li>
              <li>Real Estate Marketplace</li>
              <li>Realtor.com</li>
              <li>Realty.com LLC</li>
              <li>Redefy</li>
              <li>Request Results</li>
              <li>Resource Marketing Corp.</li>
              <li>Saygo Home Offers</li>
              <li>Sebastian Frey</li>
              <li>SFR Go</li>
              <li>Smart Leads</li>
              <li>Sold.com</li>
              <li>Suited Connector, LLC</li>
              <li>Swift Homes</li>
              <li>The Home Improvement Specialist</li>
              <li>The Mitchell Group</li>
              <li>The Owner Advocate</li>
              <li>The Listing Advocate</li>
              <li>Theresa Diele</li>
              <li>THK Investments</li>
              <li>Tigfors LLC</li>
              <li>TML Realty, Inc</li>
              <li>Tony Elias</li>
              <li>Top Agent Marketing Solutions</li>
              <li>Township Mortgage Inc.</li>
              <li>True Properties</li>
              <li>Tyler Hungerford</li>
              <li>United Wholesale Mortgage</li>
              <li>Unlock</li>
              <li>Wade Warner</li>
              <li>We Buy Homes Near You LLC</li>
              <li>Wisdom Companies</li>
              <li>X5 Ventures</li>
              <li>Zillow</li>
            </ol>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PartnerList;
