import { HOUSE_LISTED_WITH_REALTOR_OPTIONS } from '../../../lib/constants';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';

function FormStep8() {
  const { register, setValue, trigger, formState } = useFormContext();
  const { updateFormData, formData, updateStep } = useFormDataContext();
  const { errors } = formState;
  const house_listed_with_realtorRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };

  const handleClickContinue = async (data) => {
    const isValid = await trigger(['realtorlisting']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        realtorlisting: house_listed_with_realtorRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1); // Update step on radio select
    }
  };
  const handleRadioChange = async (e) => {
    await setValue('realtorlisting', e.target.value);
    updateFormData({ realtorlisting: e.target.value });
    handleClickContinue();
  };
  return (
    <>
      <div id="question8">
        <h3 className="step-title">Is your house listed with a Realtor?</h3>
        <ul className="radio-list">
          {HOUSE_LISTED_WITH_REALTOR_OPTIONS.map(
            ({ id, value, textOption, registerLabel }, index) => (
              <li
                key={`${id}-${index}`}
                className={`radio-list-item ${
                  errors[registerLabel] && 'form-step__error'
                }`}
                onClick={handleClick}
              >
                <input
                  type="radio"
                  value={value}
                  id={id}
                  ref={house_listed_with_realtorRef}
                  checked={formData.realtorlisting === value}
                  {...register(registerLabel, { required: true })}
                  onClick={handleRadioChange}
                />
                <label className="radio-button">
                  <span htmlFor={id}>{textOption}</span>
                  <div className="radiobutton"></div>
                </label>
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
}

export default FormStep8;
