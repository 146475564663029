import {
  AddressProvider,
  AppProvider,
  FormDataProvider,
  LocationProvider,
  ZipProvider,
  PrePopProvider,
} from './';

import { HelmetProvider } from 'react-helmet-async';

const CombinedProviders = ({ children }) => (
  <HelmetProvider>
    <PrePopProvider>
      <AddressProvider>
        <AppProvider>
          <ZipProvider>
            <LocationProvider>
              <FormDataProvider>{children}</FormDataProvider>
            </LocationProvider>
          </ZipProvider>
        </AppProvider>
      </AddressProvider>
    </PrePopProvider>
  </HelmetProvider>
);

export default CombinedProviders;
