import React, { forwardRef } from 'react';
import { handleKeyDown } from '../../lib/utils';
import { useFormContext } from 'react-hook-form';

const CustomInput = forwardRef(({
  name,
  register,
  onInputChange,
  validation,
  ...props
}, ref) => {
  const { setValue, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  return (
    <>
      <input
        {...props}
        ref={ref}
        onKeyDown={handleKeyDown}
        className={`form-control text-grey ${
          errors[name] ? 'form-control text-grey error' : ''
        }`}
        autoComplete="off"
        {...register(name, validation)}
        onChange={(e) => {
          onInputChange({ [name]: e.target.value });
          setValue(name, e.target.value);
          clearErrors(name);
        }}
      />
      {errors[name] && <span className="error-message">{errors[name].message}</span>}
    </>
  );
});

export default CustomInput;
