function Client({ testimony, author, image, address }) {
  return (
    <>
      <div className="">
        <img src={image} alt={author} />
      </div>
      <div className="">
        <h2>{author}</h2>
        <h3>{address}</h3>
        <p>{testimony}</p>
      </div>
    </>
  );
}

export default Client;
