import React from 'react';

function RibbonArea() {
  return (
    <div>
      <section className="ribbonArea py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h5 className="text-white font-xbold m-0">
                Need to Sell, but don’t want to move we can help with that too!{' '}
                <span>SELL NOW, MOVE LATER!</span>
              </h5>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RibbonArea;
