import './mainPage.css';
import {
  BenefitArea,
  ClientArea,
  FaqArea,
  HeroArea,
  ProgramArea,
  RibbonArea,
  Testimonials,
  WorkArea,
} from './';
function MainPage() {
  return (
    <>
      <HeroArea />
      <RibbonArea />
      <Testimonials />
      <WorkArea />
      <BenefitArea />
      <ProgramArea />
      <ClientArea />
      <FaqArea />
    </>
  );
}

export default MainPage;
