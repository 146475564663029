import React from 'react';
import calendar from '../../../assets/img/calendar.png';
import estateAgent from '../../../assets/img/estate-agent.png';
import noRepair from '../../../assets/img/no-repair.png';
import noVisiting from '../../../assets/img/no-visiting.png';
function BenefitArea() {
  return (
    <div>
      <section className="benifitArea py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="text-green titleSml font-bold">Benefits</h4>
              <h3 className="text-blue font-bold my-0 mx-auto">
                Want a stress-free home sale without all the hassles of dealing
                with buyers and Realtors.
              </h3>
            </div>
          </div>
          <div className="row py-5 mt-4">
            <div className="col-6 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
              <img src={noRepair} alt="" />
              <h5 className="text-blue font-xbold my-3 mx-auto">No repairs</h5>
            </div>
            <div className="col-6 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
              <img src={estateAgent} alt="" />
              <h5 className="text-blue font-xbold my-3 mx-auto">
                No realtor commissions
              </h5>
            </div>
            <div className="col-6 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
              <img src={noVisiting} alt="" />
              <h5 className="text-blue font-xbold my-3 mx-auto">
                No open houses or showings
              </h5>
            </div>
            <div className="col-6 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
              <img src={calendar} alt="" />
              <h5 className="text-blue font-xbold my-3 mx-auto">
                Pick your close date
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center pt-3 pb-5 mb-3">
              <a href="#form" className="ctaGreen">
                Get My Instant Cash Offer
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BenefitArea;
