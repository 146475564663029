import React, { createContext, useContext, useEffect, useState } from 'react';
import {usePrePopContext} from "./PrePopulationContext";

const defaultState = {
  type: 'homeseller',
  publisher_id: null,
  subid: null,
  source: process.env.REACT_APP_SOURCE,
  cake: null,
  prepop: null,
};

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [appContext, setAppContext] = useState(defaultState);
  const prePop = usePrePopContext();

  useEffect(() => {
    const getUrlParameter = (sParam) => {
      const sPageURL = decodeURIComponent(window.location.search.substring(1));
      const sURLVariables = sPageURL.split('&');
      for (let i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
        }
      }
    };

    const AffiliateID = getUrlParameter('AffiliateID');
    const S1 = getUrlParameter('S1');
    const PublisherID = getUrlParameter('publisher_id');
    const SubId = getUrlParameter('subid');
    const Cake = getUrlParameter('cake');

    if (AffiliateID) {
      process.env.REACT_APP_USER_MAP.split(',').map((elemMap) => {
        const elemMapItem = elemMap.split('=');

        if (parseInt(elemMapItem[0]) === parseInt(AffiliateID)) {
          setAppContext((prevState) => ({ ...prevState, publisher_id: parseInt(elemMapItem[1]) }));
          localStorage.setItem('publisher_id', parseInt(elemMapItem[1]));
        }
      });
    } else {
      if (PublisherID) {
        setAppContext((prevState) => ({...prevState, publisher_id: PublisherID}));
        localStorage.setItem('publisher_id', PublisherID);
      }else {
        setAppContext((prevState) => ({...prevState, publisher_id: 1}));
        localStorage.setItem('publisher_id', 1);
      }
    }

    if (S1) {
      setAppContext((prevState) => ({ ...prevState, subid: S1 }));
      localStorage.setItem('subid', S1);
    }

    if (SubId) {
      setAppContext((prevState) => ({ ...prevState, subid: SubId }));
      localStorage.setItem('subid', SubId);
    }

    if (Cake) {
      setAppContext((prevState) => ({ ...prevState, cake: Cake }));
      localStorage.setItem('cake', Cake);
    }

    if (prePop) {
      setAppContext((prevState) => ({ ...prevState, prepop: prePop }));
    }
  }, [prePop]);

  return (
    <AppContext.Provider value={{ appContext }}>{children}</AppContext.Provider>
  );
};
