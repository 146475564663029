import React from 'react'
import styled from 'styled-components';
import { useFormDataContext } from '../../lib/contexts';
import { useNavigate } from 'react-router-dom';

const BackCointainer = styled.div`
  margin: 0 auto;
  margin-top: 20px;
`;

const Backbutton = styled.div`
  color: #45BF7C;
  cursor: pointer;
  font-weight: bold;
`;

export default function CustomBackButton({clickHome}) {
  const { updateFormData, formData } = useFormDataContext();
  const navigate = useNavigate();

  const handleReturn = () =>{
    if(clickHome){
      navigate('/');
      window.scrollTo(0, 0);
      clickHome = !clickHome;
      return
    }
    updateFormData({ step: formData.step - 1 });
  }

  return (
    <BackCointainer
      onClick={handleReturn}
    >
      <Backbutton>
        &#8592; Back
      </Backbutton>
    </BackCointainer>
  )
}
