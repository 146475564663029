import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
require('react-bootstrap/ModalHeader');
require('bootstrap/dist/css/bootstrap.css');
function PrivacyPolicy() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    event.preventDefault();
    setShow(true);
  };

  return (
    <>
      <a className="custom-submit_button-as-link" onClick={handleShow} href="#">
        Privacy Policy
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PRIVACY POLICY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-modal__body">
            <h4>PRIVACY POLICY</h4>
            <h4>
              Last Updated February 1, 2023
              <br />
            </h4>
            <p>
              instantcashoffers.net (“Company” “we” or “our”) is committed to
              advising you of the right to your privacy, and strives to provide
              a safe and secure user experience. This Privacy Policy explains
              how we collect, store and use personal information provided by you
              on our Website. It also explains how we collect and use
              non-personal information. By accessing and using our Website, you
              explicitly accept, without limitation or qualification, the
              collection, use and transfer of the personal information and
              non-personal information in the manner described in this Privacy
              Policy. Please read this Policy carefully, as it affects your
              rights and liabilities under the law. If you disagree with the way
              we collect and process personal and non-personal information,
              please do not use this Website.
              <br />
            </p>

            <h4>Information We Collect</h4>
            <p>
              We collect the following types of information about you when you
              visit and use our Website “Personal information” is information
              that can be used to identify you or any other individual to whom
              the information may relate. This is information which you are
              prompted to provide to us. Such information may include your name,
              address, telephone number(s), mobile numbers, date of birth, and
              email address, or other unique information about you which you
              provide to us during the registration process, or through the
              course of communicating with us about the products and services
              provided on our Website.&nbsp;
            </p>
            <p>
              “Demographic information” is information that may or may not be
              unique to you in the sense that it refers to selected population
              characteristics. Such information may include, but is not limited
              to, zip code, mobile phone carrier, age, gender, salary range,
              education and marital status, occupation, industry of employment,
              personal and online interests.
            </p>
            <p>
              “Behavioral information” is information which is automatically
              collected pertaining to how you use our Website, the areas of our
              Website that you visit, what services you access, and information
              about your computer hardware and software, including your IP
              address, geographic location, browser preference, operating system
              type, domain names, times that you access the internet, and other
              websites you have visited.
            </p>
            <p>
              “Third party collected information” is information about you that
              we acquire from a third party which may include personal,
              demographic, behavioral and indirect information. This collection
              may include, but not limited to, first party cookies, third party
              cookies, anonymous cookies, persistent identifiers, email opt in,
              and search engine keywords. We have no access or control over
              these cookies and other tracking devices used by data aggregators,
              third party advertisers and third party networks. We have no
              responsibility or liability for the policies and practices of
              these parties.
            </p>
            <p>
              “Web technology information”&nbsp;is information that may include
              personal or non-personal that we, or third parties we contract
              with to independently document users’ permission to be contacted.
              This site uses two services. ActiveProspect’sTrustedForm Script is
              embedded on this site and collects the following information
              when&nbsp;you interact with the web pages where the script is
              present. This technology will&nbsp;capture the page URL, mouse
              movements and clicks, contact information that user&nbsp;inputs on
              site, a snapshot of the webpages, IP address of the user’s
              computer, time on the webpages, date and time the TrustedForm
              Script was loaded, HTTP headers&nbsp;from the user’s browser. For
              information regarding ActivePropect’s data use&nbsp;and collection
              practices and policies please see&nbsp;
              <a href="https://activeprospect.com/privacy-policy/">
                TrustedForm
              </a>
              . This site also embeds&nbsp;Jornaya’s LeadiD script which
              collects information such as browser type, internet service
              provider (ISP), referring and exit pages, platform type, date/time
              stamp, IP address, number of clicks including tracking users’
              movements on the site(including the capture of visual snapshots of
              the users’ activity on the site). For information&nbsp;regarding
              Jornaya’s data use and collection practices and policies
              please&nbsp;see&nbsp;
              <a href="https://www.jornaya.com/consumer-privacy-policy/">
                LeadiD
              </a>
              . This information is combined with other aggregate data and
              assigned&nbsp;a unique identifier for that site visit.
            </p>
            <p>
              No Information Collected from Children. We will never knowingly
              collect any Personal Information from children under the age of
              13. If we obtain actual knowledge that we have collected Personal
              Information about a child under the age of 13, that information
              will be immediately deleted from our database. Because we do not
              collect such information, we have no such information to use or to
              disclose to third parties.
            </p>
            <h4>Cookies, Web Beacons, and JavaScript</h4>
            <p>
              Generally, we as well as third party vendors and supporting
              advertisers use technologies such as cookies, web beacons, and
              java scripts to collect information. These technologies collect
              internet protocol (IP) addresses, browser type, internet service
              provider (ISP), referring/exit pages, operating system, date/time
              stamp, and/or clickstream data. This information is used to
              analyze trends, administer our Website, track user&#39;s movements
              through our Website and gather demographic information about our
              user base as a whole. We may receive reports based on these
              technologies on an individual or aggregated basis.
            </p>
            <p>
              “Cookies” are a feature in your browser software. If enabled,
              cookies store small amounts of data on your computer about actions
              you take on the pages of our Website including the placement of
              identifiers. Cookies assist us in tracking which of our features
              you visit most often, and what content you viewed on past visits.
              When you visit this Website again, cookies allow us to remember
              your settings and may be used for authentication. We may use
              cookies to keep track of the number of return visits, accumulate
              and aggregate statistical information generally pertaining to our
              Website, and deliver specific content to you based on your past
              viewing history. You can disable cookies, although our Website may
              not function properly for you. Your browser preferences can be
              modified to accept or reject all cookies, or request a
              notification when a cookie is set. You may read more about cookies
              at{' '}
              <a
                href="http://cookiecentral.com"
                target="_blank"
                rel="noreferrer"
              >
                http://cookiecentral.com
              </a>
              . In order to use all of the features and functionality of our
              Website, you need to accept cookies.
            </p>
            <p>
              Third Party Cookies. We allow third party vendors and advertisers
              to set their own cookies on and through our Website. We have no
              control over the practices of those third parties and are not
              responsible for their technology or tracking. We encourage you to
              review the policies of such persons or entities on their websites.
              We use AdWords Remarketing through Google which is a Remarketing
              and Behavioral Targeting service provided by Google Inc. that
              connects the activity on our Website with the AdWords advertising
              network and the DoubleClick cookie. That Cookie collects
              information regarding certain patterns in your browsing history.
              To opt-out of this tracking, please see{' '}
              <a
                href="https://support.google.com/ads/answer/2662922?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                https://support.google.com/ads/answer/2662922?hl=en
              </a>
              . We also participate in all of Google Analytics Advertising. This
              includes (i) Remarketing with Google Analytics; (ii) Google
              Display Network Impression Reporting; (iii) DoubleClick Campaign
              Manager integration; and (iv) Google Analytics Demographics and
              Interest Reporting. Google uses cookies to track activity
              performed by Google Analytics and its AdWords or DoubleClick
              cookie. To opt-out please see{' '}
              <a
                href="https://tools.google.com/dlpage/gaoptout/"
                target="_blank"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout/
              </a>
              . We also use Google Analytics which is an analysis service also
              provided by Google Inc. Google utilizes the data collected through
              its cookies to track and examine the use of this site, to prepare
              reports on its activities, and to share them with other Google
              services. You may opt-out of the Google Analytics service using
              Google’s Browser Add-available at{' '}
              <a
                href="https://tools.google.com/dlpage/gaoptout/"
                target="_blank"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout/
              </a>
              .
            </p>
            <p>
              Social Media Cookies/Plug-ins. Plug-ins for social media including
              Facebook, Twitter, LinkedIn, Yahoo, Windows and Google Plus are
              integrated on our Website. By interacting with us through a social
              media plug-in, certain information will be transmitted to the
              social network and you permit us to have continued access to
              information from your profile. Social media features are either
              hosted by a third party or hosted directly on our Website. Your
              interactions with these features are governed by the privacy
              policy of the company providing it.
            </p>
            <p>
              Web Beacons. We use electronic images known as Web Beacons
              (sometimes called single-pixel gifs, clear gifs or action tags)
              which allow us to collect information about your visit to our
              Website, measure and improve the effectiveness of advertisements
              and track delivery of advertising. Web Beacons collect only a
              limited set of information including a cookie number, time and
              date of page view, as well as a description of the page on which
              the Web Beacon resides. We may also use Web beacons in email
              messages sent to you. This allows us to determine if you opened or
              acted upon the email messages. Because Web beacons are the same as
              any other content request, you cannot opt out or refuse them.
              However, they can be rendered ineffective by either opting out of
              cookies or changing the cookie setup in your browser.
            </p>
            <p>
              JavaScript. We may also use JavaScript. JavaScript is a computer
              language that enhances the functionality of websites, particularly
              with respect to pictures. We use it to analyze and improve our
              Website’s functions. You may deactivate JavaScript through your
              browser settings or activate it the same way. If you disable
              JavaScript, you will not be able to use some of the functions of
              our Website.
            </p>
            <p>
              Proprietary Auto-Fill Tracking. At times, we use third party
              vendors who have technology which collects information held in
              your browser’s auto-fill functionality This collection allows them
              to notify you of an error in the registration or a failure to
              submit the registration. You can stop this functionality by
              turning it off in your browser. There is no loss of use of our
              Website or services should this browser function be turned off.
            </p>
            <h4>How We Use Information Collected</h4>
            <p>
              Providing Services and Products. We use the information we gather
              on our Website to provide you with the services and or products
              you have requested. After visiting one of our client’s websites,
              we use the information we have collected during your visit to send
              you an email to complete your registration or provide you with
              additional offers that may be of interest to you. This may include
              passing your information on to a third party to provide such
              services or products. Although our contractual arrangement limits
              how this party can use your information, we do not control the
              privacy practices of third parties. If you have any questions or
              wish to remove your information from the third party databases,
              you will need to contact that party directly.
            </p>
            <p>
              Improving Our Website. We use the information we gather to respond
              to any inquires you make, operate and improve the functionality of
              our Website, and deliver the products and services advertised on
              our Website. Our services include the display of personalized
              products, content, and advertising, relating to your experience
              and interests.
            </p>
            <p>
              Targeted Advertising. Based on User Information, we may customize
              and target advertising to an individual. In our discretion, we may
              combine information to target advertising to an individual on the
              Site, in email and/or through direct mail. Such advertising may be
              different to the products or services offered or promoted on our
              Website.
            </p>
            <p>
              Commercial Email. We may use your email address to promote goods
              and services of third parties that may be of interest to you and
              these may be different than the products or services offered or
              promoted on the Site.
            </p>
            <p>
              Direct Mail. We may use User Information to advertise, directly or
              indirectly to individuals using postal mail.
            </p>
            <p>We Share Your Information.</p>
            <h4>
              We will share your personal information with third parties in the
              following ways:
            </h4>
            <p>
              Product and Service Delivery We share your Personal information
              with third parties who help us in the delivery of the products and
              services you have requested.
            </p>
            <p>
              Website Functionality. We share your Personal and Non-Personal
              information with companies and individuals we employ to perform
              technical functions on our behalf. Examples include third parties
              who host our Website, analyze our data, provide marketing
              assistance, process credit card payments, and provide customer
              service.
            </p>
            <p>
              Third Party Products and Services. We share your Personal and
              Non-Personal Information with third parties who will provide you
              with their opportunities, products or services. This includes your
              personal and non-Personal information, and includes your interests
              and preferences, so they may determine whether you might be
              interested in their products or services.
            </p>
            <p>
              Anonymous information. We share aggregated anonymous information
              about you, combined with other persons using our Website with
              third parties, so that they can understand the kinds of visitors
              that come to our Website, and how those visitors use our Website.
              This includes demographic information and behavioral information.
            </p>
            <p>
              Legal Process. We disclose and share your information if legally
              required to do so, or at our discretion, pursuant to a request
              from a governmental entity, or if we believe in good faith that
              such action is necessary to (a) conform to legal requirements or
              comply with legal process; (b) protect our rights or property, or
              our affiliated companies; (c) prevent a crime or protect national
              security; or (d) protect the personal safety of users or the
              public.
            </p>
            <p>
              Acquisition or Merger. We may disclose and transfer your
              information to a third party who acquires any or all of our
              business, whether such acquisition is by way of merger,
              consolidation or purchase of all or a substantial portion of our
              assets. In the event we become the subject of an insolvency
              proceeding, whether voluntary or involuntary, we or our
              liquidator, administrator, receiver or administrative receiver may
              sell, license or otherwise dispose of, such information in a
              transaction approved by the court.
            </p>
            <h4>Third Party Collection and Use of Information</h4>
            <p>
              Third Parties collect and use information about you on or through
              our Website in the following ways:
            </p>
            <p>
              Service Providers and Advertisers. Service Providers of the
              service or product you have requested, Advertising agencies,
              advertising networks, and other companies who place ads on our
              Website, may use their own cookies, Web beacons, and other
              technology, to collect information about you. We do not control
              the use of such technology and have no responsibility for the use
              of such technology to gather information about you. Note that
              service providers may use such Personal information to perform a
              “soft” inquiry of credit, in connection with providing you with
              the requested service or product.
            </p>
            <p>
              Hyperlinks. Our Website and email messages sometimes contain
              hypertext links to Websites owned by third parties. We are not
              responsible for the privacy practices or the content of such other
              Websites. These links are provided for your convenience and
              reference only. We do not operate or control any information,
              software, products or services, available on these third party
              Websites. The inclusion of a link on our Website does not imply
              any endorsement of the services, products or website, or its
              sponsoring organization.
            </p>
            <p>
              Analytics. As described above, we use third parties to monitor
              analyze and report on the traffic to, from and within our Website
              and email messages.
            </p>
            <p>
              Disclaimer. We do not control the collection and use of any
              information collected by Third Parties. Please review their
              policies and terms before providing any information.
            </p>
            <p>Information Security and Retention</p>
            <p>
              We use industry standard precautions to safeguard your Personal
              Information from loss, theft and misuse including unauthorized
              access, disclosure, alteration and destruction. These precautions
              are technical, physical and managerial. We have security measures
              in place to protect against the loss, misuse, and alteration of
              Personal information under our control. The servers in which we
              store your information are kept in a secure physical environment.
              The servers have industry standard firewalls. Access to such
              servers is password protected and access by our employees is
              limited. Currently, we use Secure Socket Layer software
              (&#34;SSL&#34;) to protect data and to secure any transactions.
              SSL encrypts information including credit card number(s), and
              names and addresses, as they are transmitted over the Internet.
              Please be advised that, although we take commercially reasonable
              technological precautions to protect your data, no data
              transmission over the Internet can be guaranteed to be 100% secure
              from improper actions of third parties not under our control;
              therefore, we cannot and do not warrant that your information will
              be absolutely secure. Any transmission of information through our
              Website or through email communications is at your own risk.
            </p>
            <p>
              We retain Personal Information for the time necessary to fulfill
              the purpose for which you provided the information and retain it
              thereafter for a period permitted by law. We retain Non-Personal
              Information indefinitely as the same is used by us for any proper
              purpose, including but not limited to analysis, development and
              improvement of our Website, services and products.
            </p>
            <h4>Changes to Privacy Policy</h4>
            <p>
              We reserve the right to make material changes to the substance of
              this Privacy Policy. We will post those changes through a
              prominent notice on the Website, so that you will always know what
              information we gather, how we might use that information, and to
              whom we will disclose it. California Resident Rights.
            </p>
            <p>
              California residents have the right to receive, once a year,
              information about third parties with whom we have shared
              information about you or your family for their marketing purposes
              during the previous calendar year, and a description of the
              categories of Personal information shared. In addition, California
              residents have the right to know if we respond to do not track
              signals or cookies. We do not respond to such signals or cookies.
            </p>
            <p>
              As stated in this Policy, you have agreed to allow us to share
              information with third parties for their direct marketing purposes
              until you remove your information; and thus, you have agreed to
              this disclosure. California customers may request further
              information about our compliance with this law by sending us an
              email at info@rgrmarketing.com. Please note that we are only
              required to respond to one request per customer each year, and we
              are not required to respond to requests made by means other than
              through this email address.
            </p>
            <h4>GDPR and CASL Compliance</h4>
            <p>
              Unfortunately, we are not in a position to accept registrants who
              are not U.S. Citizens. The CASL and GDPR regulations, in
              particular, provide certain rights to their citizens which are not
              the same as the United States. For this reason, we do not accept
              registrations from any citizen outside the United States. Our
              services are specifically designed for United States citizens. We
              have expunged all European Union Member and Canadian Citizens data
              to the extent we have been made aware of the same. If you are a
              European Union Member, Canadian or any country outside of the
              United States citizen, please notify us at info@rgrmarketing.com.
            </p>

            <h4>Personal Information Collected</h4>
            <p>
              We collect Personal Information provided by Consumers through the
              Site. In the past 12 months, we have collected the following
              Personal Information from Consumers:
            </p>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th>Category of Information</th>
                    <th>Examples</th>
                    <th>Information Collected</th>
                  </tr>
                  <tr>
                    <td>Identifiers</td>
                    <td>
                      A real name, alias, postal address, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, date of birth, account name, Social
                      Security number, driver&#39;s license number, passport
                      number, or other similar identifiers.
                    </td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>
                      Categories of personal information described in
                      subdivision (e) of Section 1798.80.
                    </td>
                    <td>
                      A name, signature, social security number, physical
                      characteristics or description, address, telephone number,
                      passport number, driver&#39;s license or state
                      identification card number, insurance policy number,
                      education, employment, employment history, bank account
                      number, credit card number, debit card, or any other
                      financial information, or health insurance information.
                    </td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>
                      Characteristics of protected class ifications under
                      California or federal law
                    </td>
                    <td>
                      Age (40 years or older), race, color, ancestry, national
                      origin, citizenship, religion or creed, marital status,
                      medical condition, physical or mental disability, sex
                      (including gender, gender identity, gender expression,
                      pregnancy or childbirth and related medical conditions),
                      sexual orientation, veteran military status, genetic
                      information (including familial genetic information).
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Commercial Information</td>
                    <td>
                      Records of personal property, products or services
                      purchased, obtained, or considered, or other purchasing or
                      consuming histories or tendencies.
                    </td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Biometric information</td>
                    <td>
                      An individual&#39;s physiological, or biological or
                      behavioral characteristics, including an individual&#39;s
                      deoxyribonucleic acid (DNA), that can be use, singly or in
                      combination with each other or with other identifying
                      data, to establish individual identity, including but not
                      limited to, imagery of the iris, retina, fingerprint,
                      face, hand, palm, vein patterns, and voice recordings,
                      from which an identifier template, such as a faceprint, a
                      minutiae template, or a voiceprint, can be extracted, and
                      keystroke patterns or rhythms, gait patterns or rhythms,
                      and sleep, health, or exercise data that contain
                      identifying information.
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      Internet or other electronic netowrk activity information
                    </td>
                    <td>
                      Browsing history, search history, and information
                      regarding a consumer&#39;s interaction with an Internet
                      Web site, application, advertisement.
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Geolocation</td>
                    <td>Physical location, position, and movement data.</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Sensory information</td>
                    <td>
                      Audio, electronic, visual, thermal, olfactory, or similar
                      information.
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Professional or employment-related information</td>
                    <td>Present and past employment data.</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Education information</td>
                    <td>
                      Education records maintained by educational institutions
                      or third parties on behalf of these institutions.
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Inferences drawn from any other information</td>
                    <td>
                      Data reflecting a consumer&#39;s preferences,
                      characteristics, habits, attitudes, predispositions,
                      intelligences, and abilities.
                    </td>
                    <td>No</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              The categories of Personal Information described above are
              collected from the following categories of sources:
            </p>
            <ul>
              <li>
                Directly from Consumers or their agents, such as through
                Consumers’ responses to our web forms.
              </li>
              <li>
                Directly and indirectly from activity on our Website, such as
                through Consumers’ interaction with our Website.
              </li>
              <li>
                From third parties that work with us to provide services to
                Consumers, such as third parties that we connect Consumers with
                to fulfill their requests.
              </li>
            </ul>

            <h4>Uses of Personal Information</h4>
            <p>The information we collect is used to:</p>
            <ul>
              <li>
                Fulfill the purpose for which the information was provided by
                the Consumer.
              </li>
              <li>
                Perform the activities described to Consumers when collecting
                their Personal Information or as set forth in the CCPA or CPRA.
              </li>
              <li>
                Provide the Consumer with information, products, or services
                that have been requested. Note that our third party service
                providers may use such Personal Information to perform a “soft”
                inquiry of credit, in connection with providing the Consumer
                with the requested products and/or services.
              </li>
              <li>Perform internal and external marketing.</li>
              <li>Carry out obligations and enforce contractual rights.</li>
              <li>
                Improve the services provided through the Website, such as
                through testing, research, analysis, and development.
              </li>
              <li>
                Respond to requests from law enforcement and government
                organizations as required by law, court order, or government
                regulation.
              </li>
              <li>
                Evaluate or conduct a merger, divestiture, restructuring,
                reorganization, dissolution, sale, or other transfer of some or
                all of our assets whether on an ongoing basis or as part of a
                discrete transaction in which collected Personal Information is
                among the assets transferred.
              </li>
            </ul>
            <p>
              Personal information will not be used for materially different
              purposes without first providing the Consumer with notice.
            </p>
            <h4>Sharing and Sale of Personal Information</h4>
            <p>
              We may sell or disclose Personal Information provided to us by
              Consumers for a business purpose. In the past 12 months, the
              following categories of Personal Information have been sold or
              disclosed for a business purpose:
            </p>
            <ul>
              <li>Identifiers</li>
              <li>
                Categories of personal information described in subdivision (e)
                of California Civil Code Section 1798.80
              </li>
              <li>
                Characteristics of protected class ifications under California
                or federal law
              </li>
              <li>Commercial information</li>
            </ul>
            <p>
              Consumers’ Personal Information is sold or disclosed for business
              purposes to the following categories of third parties:
            </p>
            <ul>
              <li>Mortgage companies</li>
              <li>Banks</li>
              <li>Home improvement companies</li>
              <li>Attorneys</li>
              <li>Contact centers</li>
              <li>Email affiliates</li>
              <li>Media buyers</li>
              <li>Compliance vendors</li>
            </ul>
            <p>
              Personal information for minors under 16 years of age is not
              collected, disclosed for a business purpose or sold.
              <br />
            </p>
            <h4>California Consumers’ Privacy Rights</h4>
            <p className="MsoNormal">
              This section of the Privacy Policy supplements the information
              contained elsewhere in our Privacy Policy and applies solely to
              website visitors, users, and others who reside in the state of
              California (“Consumers”). The provisions contained within this
              section are intended to provide notices in compliance with the
              California Consumer Privacy Act of 2018 (“CCPA”) and the
              California Privacy Rights Act (CPRA). Any terms defined by the
              CCPA or CPRA have the same meaning when used in this section.
            </p>
            <p>
              <br />
              Consumers have several rights under the CCPA including the right
              to request that we disclose to the consumer the following:
            </p>

            <ul>
              <li>
                The categories of personal information we have collected about
                that consumer, the categories of sources from which the personal
                information is collected, the business or commercial purpose for
                collecting, selling, or sharing personal information, the
                categories of third parties to whom we disclose personal
                information, and the specific pieces of Personal Information we
                collected about that consumer. , disclosed for a business
                purpose, or sold by requesting this information from us.
                <br />
              </li>
              <li>
                The categories of personal information that we have sold or
                shared about the consumer and the category or categories of
                third parties to whom each category of personal information was
                sold or shared. You may also request that we disclose the
                category or categories of consumers’ personal information that
                we have disclosed for a business purpose. If we have not sold or
                shared your personal information or disclosed it for a business
                purpose, we shall disclose that fact.
                <br />
              </li>
              <li>
                The right to request deletion of Personal Information collected
                or maintained by us. We may retain your personal information
                after a deletion request under certain circumstances permitted
                by law.
                <br />
              </li>
              <li>
                The right to request that we correct inaccurate personal
                information, taking into account the nature and purposes of
                processing of the personal information.
                <br />
              </li>
              <li>
                The right to opt-out of the sale or sharing of Personal
                Information.
                <br />
              </li>
              <li>
                The right not to receive discriminatory treatment from us for
                choosing to exercise privacy rights under the CCPA or CPR
                <br />
              </li>
              <li>
                The right to designate an authorized agent for making requests
                under the CCPA or CPRA on the Consumer’s behalf.
              </li>
            </ul>

            <p>
              To submit a request to know or delete Personal Information, please
              either call our toll-free telephone number (877) 623-0003 or
              complete the form provided&nbsp;
              <a
                href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/942fbf02-bbfe-4a5d-808d-080b06c5651c/3fdf8f1d-7ab3-4a99-bb4e-24965500290d.html"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              . Once we have received a request, we work with Consumers to
              verify their identities. As part of the verification process, we
              work with OneTrust. Depending on the type of request submitted,
              OneTrust may request information from the Consumer, such as an
              email address, phone number, or government issued identification
              card. More information about OneTrust’s identification process is
              found&nbsp;
              <a
                href="https://www.onetrust.com/ccpa-id-verification/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              . If a Consumer has asked an agent to exercise rights on the
              Consumer’s behalf, the agent’s identity will need to be verified
              by providing a writing from the Consumer designating the agent in
              addition to any information requested through OneTrust.
            </p>

            <p>
              To opt-out of the sale of Personal Information, please complete
              the form located&nbsp;
              <a
                href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/942fbf02-bbfe-4a5d-808d-080b06c5651c/3fdf8f1d-7ab3-4a99-bb4e-24965500290d.html"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
            <p>
              Virginia Consumers’ Privacy Rights
              <br />
              This section of the Privacy Policy supplements the information
              contained elsewhere in our Privacy Policy, applies solely to
              website visitors, users, and others who reside in the Commonwealth
              of Virginia (“consumer” or “you”), and is adopted to comply with
              the Virginia Consumer Data Protection Act. We may update this
              Privacy Policy at any time and such revised Privacy Policy shall
              become effective as of its posting. This Privacy Notice describes
              the rights of Virginia consumers to: request information about the
              processing of their personal data; to request access to, or a copy
              of, their personal data; to correct inaccuracies; to delete their
              personal data; to opt out of certain processing; and to exercise
              rights on behalf of children under 13 years of age
            </p>
            <p>Virginia Consumer&nbsp;Personal Data Rights</p>

            <p>
              A consumer may request to exercise the following rights, or, as
              parent or legal guardian, may request to exercise the following
              rights on behalf of a child younger than 13 years of age:
            </p>
            <ol>
              <li>
                to confirm whether or not we are processing the consumer’s
                personal data and to access such personal data;
              </li>
              <li>
                to correct inaccuracies in the consumer’s personal data, taking
                into account the nature of the personal data and the purposes of
                the processing;
              </li>
              <li>
                to delete personal data provided by or obtained about the
                consumer;
              </li>
              <li>
                to obtain a copy of the consumer’s previously provided personal
                data in a portable and readily usable format to the extent
                technically feasible;
              </li>
              <li>
                to opt out of the processing of the personal data for purposes
                of (i) targeted advertising, (ii) the sale of personal data, or
                (iii) profiling in furtherance of decisions that produce legal
                or similarly significant effects concerning the consumer.&nbsp;{' '}
              </li>
            </ol>
            <p>
              &nbsp;Requests To Exercise&nbsp;Virginia Consumer Personal Data
              Rights
            </p>

            <p>
              To submit a request to exercise one of your consumer rights,
              please either call our toll-free telephone number (877) 623-0003
              or complete the form provided&nbsp;
              <a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/942fbf02-bbfe-4a5d-808d-080b06c5651c/3fdf8f1d-7ab3-4a99-bb4e-24965500290d.html">
                here
              </a>
              . We will authenticate such request using commercially reasonable
              efforts.&nbsp; If we are unable to authenticate the request, we
              shall not be required to comply and may request that the consumer
              provide additional information reasonably necessary to
              authenticate the request. If we decline your request, we shall
              inform you without undue delay within 45 days of receipt of the
              request, provide a justification for declining the request, and
              describe the appeal process which includes: Sending an email to
              info@rgrmarketing, with a subject line of &#34;VCDPA&#34; and
              explain why you feel the request is valid. If we are able to
              authenticate the request, we will respond to the consumer without
              undue delay within 45 days of the request. If reasonably necessary
              taking into account the complexity and number of the consumer’s
              requests, we may extend the response period by 45 additional days
              provided we inform you of the extension and the reason within the
              initial 45 day period. Our response to your request is provided
              free of charge up to twice annually. If your requests are
              unfounded, excessive, or repetitive, we may charge a reasonable
              fee to cover our administrative costs of replying or decline your
              request.
            </p>
            <p>
              {' '}
              If we have obtained personal data about you from a source other
              than you, we may respond to your request for deletion by (i)
              retaining your deletion request and the minimum data required
              solely to ensure that your personal data remains deleted from our
              records and not used for any purpose, or, (ii) opting you out of
              the processing of your personal data for any purpose other than
              those exempted by law.
            </p>
            <h4>Contact Us Concerning Our Privacy Policy:</h4>
            <p>
              {' '}
              If you have any questions regarding this Privacy Policy, please
              <br />
              contact: info@rgrmarketing.com
              <br />
              You may write to us at:&nbsp;
              <br />
              instantcashoffers.net
              <br />
              Customer Care
              <br />
              2041 Rosecrans Ave. Suite 3275
              <br />
              El Segundo, CA&nbsp;90245
            </p>
            <p>Revised Posting Date: February 1, 2023</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrivacyPolicy;
