import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Client from './Client';
import People1 from '../../../assets/img/Frank-Tetimonial-People-1.webp';
import People2 from '../../../assets/img/Frank-Tetimonial-People-2.webp';
import People6 from '../../../assets/img/Frank-Tetimonial-People-6.webp';
import People7 from '../../../assets/img/Frank-Tetimonial-People-7.webp';
import React from 'react';
import Slider from 'react-slick';

function ClientArea() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <section className="clientArea py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 mt-5 pb-5 mb-3">
              <Slider {...settings}>
                <Client
                  image={People7}
                  author={'Mark V.'}
                  address={'Phoenix, Arizona Resident'}
                  testimony={
                    'Instant Cash Offers provided and excellent service with their Cash & Stay program. They met all our needs and prior to Andy and his team reaching out, there were not many options. We had a hard time with any other company meeting our needs. Needed to lease prior to our new home being completed and we got it with Instant Cash Offers. Much easier to plan our move without having to do it twice which has allowed my family to concentrate elsewhere. The everlasting relationship built with this company is the best and I gladly would recommend their services.'
                  }
                />

                <Client
                  image={People2}
                  author={'Angela M.'}
                  address={'Fort Worth, TX Resident'}
                  testimony={
                    'I do not know where I would be had it not been for Instant Cash Offers program. When everyone was saying no, sorry, cant help; Instant Cash Offers said no problem. The staff is phenomenal! They answered all of my questions. Thank you!'
                  }
                />

                <Client
                  image={People6}
                  author={'Mark V.'}
                  address={'Phoenix, Arizona Resident'}
                  testimony={
                    'Instant Cash Offers provided and excellent service with their Cash & Stay program. They met all our needs and prior to Andy and his team reaching out, there were not many options. We had a hard time with any other company meeting our needs. Needed to lease prior to our new home being completed and we got it with Instant Cash Offers. Much easier to plan our move without having to do it twice which has allowed my family to concentrate elsewhere. The everlasting relationship built with this company is the best and I gladly would recommend their services.'
                  }
                />

                <Client
                  image={People1}
                  author={'Rebecca R.'}
                  address={'Phoenix, Arizona Resident'}
                  testimony={
                    'Selling my house was so easy. They are very professional and are with you every step of the way. Highly recommend.'
                  }
                />
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ClientArea;
