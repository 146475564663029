import './disclaimer.css';

import PrivacyPolicy from '../Templates/PrivacyPolicy';
import TermOfUse from '../Templates/TermOfUse';

function Disclaimer() {
  const year = new Date().getFullYear();

  return (
    <footer className="footer py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <ul className="list-unstyled justify-content-around d-flex p-0 mx-auto my-3">
              <li>
                <TermOfUse />
              </li>
              <li>
                <PrivacyPolicy />
              </li>
              <li>
                <a
                  className="disclaimer__button-as-link"
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_DO_NOT_SELL_MY_INFO,
                      '_blank'
                    )
                  }
                >
                  Do Not Sell My Info
                </a>
              </li>
              <li>
                <a
                  className="disclaimer__button-as-link"
                  onClick={() =>
                    window.open(process.env.REACT_APP_OPT_OUT, '_blank')
                  }
                >
                  Opt-Out
                </a>
              </li>
            </ul>

            <p className="text-copy font-reg text-white py-3">
              &copy; {year} Instant Cash Offers. All rights reserved.
            </p>

            <p className="text-disclaimer font-reg text-white">
              InstantCashOffers.net an RGR Marketing website provides marketing
              services for various companies involved in real estate
              transactions. The information you provide to us is provided to
              these companies in real time. If the information you provide to us
              matches the criteria they are seeking, they will contact you
              directly. In many cases we may deploy SMS to you to facilitate an
              inbound call to the service or product provider. We are paid by
              such providers for each consumer they contact directly and/or
              provide services or products. You are not charged for our
              services. We do not guarantee that a provider will accept your
              request or that their products or services will meet your needs.
              Their services and products may or may not be the best product or
              service available on the market. Completing our forms does not
              obligate you to purchase a service or product nor does it obligate
              a product or service provider to provide you with any particular
              service about which you may have inquired. We only accept
              referrals for U.S. Citizens on this Website and we specifically
              exclude all other countries including but not limited to Canadian
              and European Union Member Citizens referrals.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Disclaimer;
