import './customLoader.css';

import { useCallback, useEffect, useRef } from 'react';

const CustomLoader = ({ isLoading }) => {
  const spinnerRef = useRef();
  const degrees = useRef(0);
  const animationLoop = useRef();

  const animateTo = useCallback(() => {
    if (degrees.current === 360) {
      clearInterval(animationLoop.current);
    } else if (degrees.current < 360) {
      degrees.current++;
    } else {
      degrees.current--;
    }
  }, []);

  const draw = useCallback(() => {
    degrees.current = 0;
    animationLoop.current = setInterval(animateTo, 17000 / 360);
  }, [animateTo]);

  const startLoading = useCallback(() => {
    spinnerRef.current.style.display = 'flex';
    draw();
  }, [draw]);

  const stopLoading = useCallback(() => {
    clearInterval(animationLoop.current);
    spinnerRef.current.style.display = 'none';
  }, []);

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading, startLoading, stopLoading]);

  return (
    <div className="custom-loader" ref={spinnerRef}>
      <div className="custom-loader__text">Please Wait...</div>
      <div className="custom-loader__wrapper">
        <div className="custom-loader__container" />
        <div className="custom-loader__percentage" />
      </div>
    </div>
  );
};

export default CustomLoader;
