import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
require('react-bootstrap/ModalHeader');
require('bootstrap/dist/css/bootstrap.css');
function TermOfUse() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    event.preventDefault();
    setShow(true);
  };

  return (
    <>
      <a className="custom-submit_button-as-link" onClick={handleShow} href="#">
        Terms of Use
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms Of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-modal__body">
            <h4>
              Last Updated February 1, 2023
              <br />
            </h4>

            <p>
              Welcome to Instant Cash Offers (hereinafter referred to as
              &#34;Company&#34; &#34;we,&#34; &#34;us,&#34; or &#34;our&#34;).
              Before you begin to use the Website, please take a moment to
              review these Terms of Use (“TOU” or the &#34;Agreement&#34;) which
              is a legal agreement. By submitting any information on this
              Website, you agree to be bound by the TOU. The Agreement describes
              the terms and conditions which govern your use of the Website and
              the products and services provided through or in connection with
              the Website (collectively, &#34;Service&#34;), which may be
              updated by us from time to time without notice to you. We may also
              offer other services that are governed by different terms and
              conditions. You must read and agree with all of the terms and
              conditions contained in this Agreement and the posted Privacy
              Policy then in effect (&#34;Privacy Policy&#34;), which is
              incorporated by reference, before you use the Service. If you do
              not agree to be bound by this Agreement, you may not use or access
              the Service.
            </p>

            <h4>1. USER AGREEMENT.</h4>
            <p>
              By using this Website, you agree to be bound by and to comply with
              these TOU and the posted Privacy Policy which is incorporated
              herein as though fully set forth herein. Upon entering the
              requested information, our technology will forward your
              information to one of our participating providers or to an
              aggregator and their providers who may contact you regarding your
              request for services or products. This may be done automatically
              and a new window may appear or the provider may contact you
              directly by telephone, email or mail as set forth below. WE ARE
              NOT RESPONSIBLE FOR ANY ACTIONS AFTER YOU HAVE LEFT THIS WEBSITE.
              UPON ENTRY INTO A PARTICIPANT&#39;S WEBSITE LINKED TO THIS
              WEBSITE, YOU SHOULD CAREFULLY REVIEW THE PRIVACY POLICY AND TERMS
              AND CONDITIONS OF USE OF THAT WEBSITE BEFORE ENTERING ANY PERSONAL
              INFORMATION AS THOSE POLICES AND TERMS WILL DIFFER FROM THESE. In
              order to provide you the services you have requested, you are
              expressly giving your permission to provide any information
              collected on this Website to third parties. As such, you are
              expressly giving your permission for such third parties to contact
              you by mail, email, text messaging or telephone. By registering
              and using this Website, you agree that such act constitutes a
              purchase, an inquiry and/or an application for purposes of the
              Amended Telemarketing Sales Rule, 16 CFR §310 et seq. (the
              &#34;ATSR&#34;). Notwithstanding that your telephone number may be
              listed on the Federal Trade Commission&#39;s Do-Not-Call List (or
              a state do-not-call) list you have authorized us to contact you
              via telemarketing in accordance with the ATSR. Moreover, by
              registering with, or requesting information from, a third-party
              provider at or through the Website or other advertisement media
              made available by us (e.g., email marketing), you agree that such
              action shall constitute a purchase, an inquiry and/or an
              application with the respective third-party provider for purposes
              of the ATSR and you may be contacted via email, direct mail and/or
              telemarketing by such third-party provider in accordance with the
              ATSR. If, at any time, you do not wish to be bound by these
              conditions or you are unsatisfied with the Website, its content or
              other legal notices, you agree that your sole and exclusive remedy
              is to discontinue using this Website and you may opt-out using the
              method given below. You further represent and warrant that: (i)
              You are at least 18 years of age and possess the legal authority
              to enter into an agreement and to use the Website in accordance
              with these TOU; (ii) All information supplied by you is true and
              accurate (without limitation of the foregoing, the provision of
              any speculative, incorrect, misleading, false or fraudulent
              information is prohibited); (iii) You understand and agree that
              Company may share personally identifiable information and other
              information provided by, and aggregated information about you and
              other users with its vendors, sponsors, providers, service
              providers and marketers, lookup and reference services, other
              unaffiliated third parties, and other entities that Company
              believes are able to provide its Website users with offers and
              opportunities, as more fully described in the Company&#39;s posted
              Privacy Policy (iv) You understand that abuse of this Website may
              result in your being denied access to such Website, as determined
              by Company in its sole discretion; (v) You understand and agree
              that Company controls only the landing page and intake forms on
              this Website. Upon entry of the requested information, a
              participating Provider will be contacting you directly to provide
              you with information regarding their services and may utilize the
              information you provide to connect you with their services,
              including but not limited to perform a “soft” inquiry of credit.
              Company shall not be responsible for any Provider or aggregators
              contact with you or any subsequent agreement you may enter into
              with such Provider or aggregator; and (vi) Your use of the
              Services on this Website is subject to all applicable federal,
              state and local laws and regulations; (vii) You also give us
              permission to send you periodic updates of services and products
              which may be of interest to you through email, mail, or
              telemarketing; You understand that our providers may maintain the
              information you submitted to us whether you elect to use their
              services or not. In the event you no longer want to receive
              communications from one of our providers, you agree to notify the
              partner directly. THIS SITE IS NOT AVAILABLE TO CITIZENS OF
              MEMBER-STATES OF THE EUROPEAN UNION. We may take steps to prevent
              European Union member citizens from accessing the Site, including
              (though not necessarily) geoblocking or other technical means of
              restricting access. Nonetheless, there is no perfect technical
              solution to accomplish this, so if you are a citizen a European
              Union member state, please discontinue all use of the Site or our
              Services and refrain from doing so in the future.
            </p>

            <h4>2. SERVICES.</h4>
            <p>
              The Website is an online network marketplace. You understand and
              agree that if you submit a request through the Website, we will
              share your personal information (such as your full name, address,
              telephone number, and financial information) with participating
              providers in our network to process and fulfill your request. You
              understand and agree that we are not a real estate broker, and do
              not undertake a credit analysis or make credit decisions in
              connection with the Service (however the participating providers
              in our network may perform such actions) and that we are not a
              party to any agreement that you may make with any participating
              service provider whom you choose to use or from whom you choose to
              work with. The participating provider(s) with whom you contract to
              assist you is solely responsible for its services to you. You
              further acknowledge and agree that we are not acting as your agent
              or broker and are not recommending any particular service provider
              to you. Any compensation we may receive is paid by the
              participating service provider for advertising services we
              provided to them. We do not charge you a fee to use the Website.
              You understand that the requirements for a particular service are
              made by the participating service providers and we do not endorse,
              warrant, or guarantee the products or service provider or
              installer. Nothing contained in this Agreement shall constitute an
              offer or promise to buy or sell a home. You agree that we shall
              not be liable for any damages or costs of any type which arise out
              of or in connection with your use of the service provider&#39;s
              services. You also give us permission to send you periodic updates
              of services and products which may be of interest to you. Please
              note that information we provide you either on the website or by
              email may not be used as the sole basis for your decision to
              retain a particular service provider, and their services may not
              meet your particular needs. Please seek the advice of an
              appropriate professional for an assessment of the loan information
              provided by the lender.
            </p>

            <h4>3. CHANGES TO THE AGREEMENT AND POLICIES.</h4>
            <p>
              We reserve the right to make material changes to this Agreement
              from time to time, by posting the updated versions on the Website.
              By using the Website, you agree to be bound by any such revisions
              and should therefore periodically visit the Website to determine
              the then-current Agreement to which you are bound. Your use of the
              Website after such modifications will constitute your
              acknowledgment of the modified Agreement; and agreement to abide
              and be bound by the modified Agreement.
            </p>

            <h4>4. REJECTION, TERMINATION AND CANCELLATION.</h4>
            <p>
              Company or its participating service provider may reject any
              registration or subsequent application from any person with or
              without cause at their sole discretion. Your status as a
              registered user creates only a customer relationship with Company
              and does not create an employment relationship, an independent
              contractor relationship, an agency relationship, or any other
              relationship. You may cancel your request at any time by sending
              an e-mail to info@rgrmarketing.com.
            </p>

            <h4>5. PROHIBITED USER CONDUCT.</h4>
            <p>
              A. You are prohibited from any conduct that, in Company&#39;s sole
              discretion, restricts or inhibits any other user from using or
              enjoying the Website or any linked Website. You are prohibited
              from accessing or attempting to access private areas of the
              Website or any other user&#39;s information. You are prohibited
              from impersonating any person or entity or otherwise falsely
              stating or misrepresenting your affiliation with a person or
              entity. B. You are prohibited from using any data, content, and
              any information provided or used on the Website, as well as your
              use of our Website, products and services which will infringe or
              facilitate infringement on any copyright, patent, trademark, trade
              secret, or other proprietary, publicity, or privacy rights of any
              person or entity, including third-parties. You are prohibited from
              using any data, content or information which contains or promotes
              any viruses, Trojan horses, worms, time bombs or other computer
              programming or code that is designed or intended to damage,
              destroy, intercept, download, interfere, manipulate, or otherwise
              interrupt or expropriate the Website, data, personal information,
              software, equipment, servers, or content or facilitate or promote
              hacking or similar conduct. You are prohibited from harvesting,
              sweeping, or use any other means, to collect information about
              users of the Website; Use automated means, including spiders,
              robots, bots, scripts, crawlers, or the like, in connection with
              any activity on the Website; Resell, assign, sublicense, otherwise
              transfer, or delegate your rights or obligations under these TOU
              without the prior express written authorization of Company;
              Modify, publish, transmit, transfer or sell, reproduce, create
              derivative works from, distribute, perform, link, display or in
              any way exploit any Website content; or except as otherwise
              expressly permitted on the Website, use any information you may
              obtain from the Website (including without limitation, user
              information) to send any other person unsolicited messages,
              commercial or otherwise, by electronic, telephonic, postal or
              other means.
            </p>

            <h4>6. MARKETING MATERIALS.</h4>
            <p>
              By signing up at the Website, you are giving your consent to
              receive promotions or newsletters from Company, our affiliates
              and/or third-party marketers. If you do not wish to receive these
              emails, you may request to be removed by using the opt-out
              mechanism listed in the email messages you receive. To opt-out of
              email promotions from Company alone, you may simply use our
              convenient Opt-Out Page located at the bottom of the first page of
              the Website. Please note that exercising an opt-out mechanism only
              applies to the company with which you exercised that right.
            </p>

            <h4>
              7. THIRD PARTY CONTENT/PROMOTIONS, THIRD PARTY PRODUCTS, AND THIRD
              THIRD PARTY WEBSITE ACTIVITIES.
            </h4>
            <p>
              The Website may display and make available content, promotions,
              advertisements, and offers provided by third parties (&#34;Third
              Party Promotions&#34;), as well as goods and services offered by
              third parties (&#34;Third Party Products&#34;). You understand and
              agree that Company shall not be responsible and shall have no
              liability for any Third Party Promotion or Third Party Product or
              for your activities on any third party Websites for whom Company
              displays offers (&#34;Third Party Website Activity&#34;), and that
              you participate in or choose to click on a Third Party Promotion,
              purchase and/or use a Third Party Product, or participate in a
              Third Party Website Activity solely at your own risk. You agree
              that your sole remedy in connection with any Third Party
              Promotion, Third Party Product or Third Party Website Activity
              will be with the applicable Third Party offering the Third Party
              Promotion, Third Party Product or Third Party Website Activity and
              that you shall have no remedy against Company arising from your
              use of or participation in, or inability to use or participate in,
              any Third Party Promotion, Third Party Product or Third Party
              Website Activity.
            </p>

            <h4>8. RELATIONSHIP WITH MARKETING PROVIDERS.</h4>
            <p>
              This is an independent Website and is not affiliated with any of
              the listed products or services. Trademarks, service marks, logos,
              and/or domain names are the property of their respective owners,
              who have no association with or make any endorsement of the
              products or services provided by this Website. Furthermore,
              participating service providers are independent third parties and
              this Website is not acting as a principal, agent or broker with
              respect to any providers.
            </p>

            <h4>9. LINKED WEBSITES.</h4>
            <p>
              You may be able to link to third parties Websites (&#34;Linked
              Websites&#34;) from the Website. Linked Websites are not, however,
              reviewed, controlled, or examined by Company in any way and
              Company is not responsible for the content, availability,
              advertising, products or other materials of any such Linked
              Websites, or any additional links contained therein. Except as
              otherwise noted on the Website, these links do not imply Company
              endorsement of or association with the Linked Websites. In no
              event shall Company be liable, directly, or indirectly, to anyone
              for any loss or damage arising from or occasioned by the creation
              or use of links to the Linked Websites, the Linked Websites
              themselves, your participation in activities on such Linked
              Websites, or the information, material, products, or services
              accessed through these Linked Websites. You should direct any
              concerns to that Website&#39;s administrator or webmaster. Company
              reserves the exclusive right, at its sole discretion, to add,
              change, decline or remove, without notice, any feature or link to
              any of the Linked Websites from the Website and/or introduce
              different features or links.
            </p>

            <h4>10. INTELLECTUAL PROPERTY RIGHTS.</h4>
            <p>
              The Website contains intellectual property owned by Company and
              other parties. As between Company and you, Company is the sole
              owner of the Website and all materials on or available through the
              Website, including without limitation, all applicable U.S. and
              non-U.S. copyrights, patents, trademarks, and trade secrets, and
              other intellectual property rights thereto (collectively, the
              &#34;Website Content&#34;). Except as otherwise specifically
              provided in these TOU, you may not download or save a copy of the
              Website content or any portion thereof, for any purpose; however,
              you may print a copy of individual screens appearing as part of
              the Website content solely for your personal, non-commercial use
              or records, provided that any Company or other marks, logos or
              other legends that appear on the copied screens remain on, and are
              not removed from the printed or stored images of such screens.
              Except as otherwise expressly permitted herein, you may not
              modify, copy, publish, display, transmit, adapt or in any way
              exploit any portion of the Website content unless you first obtain
              prior written consent from Company -- and from all other entities
              with an interest in the relevant intellectual property. Any
              unauthorized attempt to modify any Website content, to defeat or
              circumvent Company security features, or to utilize this Website
              for other than its intended purposes is strictly prohibited.
            </p>

            <h4>11. DISCLAIMER OF WARRANTIES.</h4>
            <p>
              Except as expressly set forth herein, Company is not responsible
              for any incorrect or inaccurate information or entry of
              information, whether caused by users of the Website or by any of
              the equipment or programming associated with or utilized in
              connection with the Website or the products or services provided
              on or through the Website, or by any technical or human error
              which may occur in the processing of information received by
              Company. Company assumes no responsibly for any error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction or
              authorized access to, or alteration of, information received or
              submitted in connection with the Website. Company is not
              responsible for any problems, errors or technical malfunction of
              any telephone network or lines, computer on-line systems, servers
              or providers, computer equipment, or software, or any failure of
              email on account of technical problems or traffic congestion on
              the Internet or at any Website or combination thereof, including
              injury or damage to participants or to any other person&#39;s
              computer related to or resulting from use of the Website or
              Website Content. THIS WEBSITE, INCLUDING THE WEBSITE CONTENT IS
              PROVIDED &#34;AS IS&#34; AND &#34;AS AVAILABLE,&#34; WITHOUT
              WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT
              LIMITATION OF THE FOREGOING, COMPANY, AND ITS PARENT,
              SUBSIDIARIES, PARTNERS, AGENTS, AFFILIATES, LICENSORS, SERVICE
              PROVIDERS, ADVERTISERS, SUCCESSORS AND ASSIGNS SPECIFICALLY
              DISCLAIM ANY AND ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO:
              (i) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY,
              APPROPRIATENESS, RELIABILITY, TIMELINESS, USEFULNESS, OR OTHERWISE
              OF THE WEBSITE, AND THE WEBSITE CONTENT; AND (ii) ANY WARRANTIES
              OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES
              NOT WARRANT OR GUARANTEE THAT ANY PORTION OF THE WEBSITE OR THE
              WEBSITE CONTENT WILL BE FREE OF INFECTION BY VIRUSES, WORMS,
              TROJAN HORSES OR ANYTHING ELSE MANIFESTING CONTAMINATING OR
              DESTRUCTIVE PROPERTIES; OR THAT ACCESS TO THE WEBSITE OR WEBSITE
              CONTENT WILL BE UNINTERRUPTED OR ERROR-FREE.
            </p>

            <h4>12. LIMITATION OF LIABILITY.</h4>
            <p>
              IN NO EVENT WILL COMPANY, ITS PARENTS, SUBSIDIARIES, PARTNERS,
              AGENTS, AFFILIATES, LICENSORS, PROVIDERS, SUCCESSORS AND ASSIGNS
              AND THEIR RESPECTIVE OFFICERS, DIRECTORS, AND EMPLOYEES AND
              SHAREHOLDERS BE LIABLE TO ANY PARTY (i) FOR ANY INDIRECT, DIRECT,
              SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING,
              BUT NOT LIMITED TO, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS
              INTERRUPTION, LOSS OF PROGRAMS OR INFORMATION, AND THE LIKE), OR
              ANY OTHER DAMAGES ARISING IN ANY WAY OUT OF THE AVAILABILITY, USE,
              RELIANCE ON, OR INABILITY TO USE THE WEBSITE OR WEBSITE CONTENT,
              EVEN IF ALL SUCH PARTIES SHALL HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE FORM OF ACTION,
              WHETHER IN CONTRACT, TORT, OR OTHERWISE; (ii) FOR ANY CLAIM OR
              DAMAGES IN EXCESS OF $500; OR (iii) FOR ANY CLAIM ATTRIBUTABLE TO
              ERRORS, OMISSIONS, OR OTHER INACCURACIES IN, OR DESTRUCTIVE
              PROPERTIES OF THE WEBSITE OR THE WEBSITE CONTENT.
            </p>

            <h4>13. EXCLUSIONS AND LIMITATIONS.</h4>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
              WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN
              SUCH JURISDICTION SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED
              BY LAW.
            </p>

            <h4>14. MONITORING WEBSITE USAGE.</h4>
            <p>
              You agree that Company may electronically monitor the Website and
              disclose any content, records, or electronic communication of any
              kind (i) to satisfy any legal process or request; (ii) to operate
              the Website; or (iii) to protect Company rights or the rights of
              the users, sponsors, providers, licensors, or merchants.
            </p>

            <h4>15. INDEMNITY.</h4>
            <p>
              You agree to defend, indemnify and hold Company, its parents,
              subsidiaries, partners, agents, affiliates, licensors, providers,
              successors and assigns and their respective officers, directors,
              employees and shareholders harmless from any and all claims,
              liabilities, costs and expenses, including reasonable attorneys
              fees, arising in any way from, in connection with or as a result
              of your use or inability to use the Website and or Website
              Content, any information provided to you by the Website, or any
              violation of TOU by you. YOU HEREBY AGREE TO WAIVE ALL LAWS THAT
              MAY LIMIT THE EFFICACY OF SUCH RELEASES. FOR EXAMPLE, YOU
              SPECIFICALLY AGREE TO WAIVE THE PROVISIONS OF CALIFORNIA CIVIL
              CODE SECTION 1542, WHICH PROVIDES; &#34;A GENERAL RELEASE DOES NOT
              EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
              EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IS
              KNOWN BY HIM, MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH
              THE DEBTOR.&#34;
            </p>

            <h4>16. RELEASE.</h4>
            <p>
              YOU HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE COMPANY
              AND ITS AFFILLIATES, PARTNERS, SERVICE PROVIDERS, CLIENTS,
              VENDORS, AND CONTRCTORS AND EACH OF THEIR RESPECTIVE AGENTS,
              DIRECTORS, OFFICERS, EMPLOYEES, AND ALL OTHER RELATED PERSONS OR
              ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS,
              DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABLITIES, OBLIGATIONS,
              LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER,
              WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM,
              RELATE TO, OR ARE CONNECTED WITH YOUR USE OF THE SERVICE.
            </p>

            <h4>17. DEALINGS WITH THIRD PARTIES.</h4>
            <p>
              Your correspondence or business dealings with any third parties as
              a result of your use of this Website and participation in the
              Service, including, but not limited to, business dealings with
              service providers, or any other terms, conditions, warranties,
              representations associated with such dealings, are solely between
              you and such third party. You agree that Company shall not be
              responsible or liable for any loss or damage of any sort incurred
              as the result of any such dealings or as the result of the
              presence of such third party on the Website.
            </p>

            <h4>18. DISPUTE RESOLUTION.</h4>
            <p>
              This Agreement will be interpreted in accordance with the laws of
              the State of California, without regard to the conflicts of laws
              principles thereof. You agree that any and all disputes, claims or
              controversies arising out of or relating to your visit to this
              Website, your dealings with the Company or its partners (whose
              names you have accessed and read), or this Agreement, its
              interpretation, performance, or breach, that are not resolved by
              informal negotiation within thirty (30) days (or any mutually
              agreed extension of time), shall be submitted to final and binding
              arbitration before a single arbitrator of the JAMS in Los Angeles,
              California. Either party may commence the arbitration process
              called for herein if good faith negotiations have failed by
              submitting a written demand for arbitration with JAMS, and
              providing a copy to the other party. The arbitration will be
              conducted in accordance with the provisions of JAMS JAMS&apos;
              Comprehensive Arbitration Rules and Procedures in effect at the
              time of submission of the demand for arbitration. Each party will
              bear their own costs of arbitration and attorney’s fees. The
              Arbitrator may not award attorney fees or costs to either party in
              such arbitration. Judgment on the award rendered by the arbitrator
              may be entered in the Superior Court of California, Los Angeles
              County, or the United States District Court for the Central
              District of California. Notwithstanding the foregoing, the
              following shall not be subject to arbitration and may be
              adjudicated only in the Superior Court of California, Los Angeles
              County, or the United States District Court for the Central
              District of California: any dispute, controversy, or claim
              relating to or contesting the validity of Company&apos;
              proprietary rights, including without limitation, trademarks,
              service marks, copyrights, or trade secrets. To the fullest extent
              permitted by applicable law, no arbitration under this Agreement
              shall be joined to an arbitration involving any other party
              subject to this Agreement, whether through class arbitration
              proceedings or otherwise. You waive any and all rights to bring
              any claim in another form other than individual form, including
              the right to bring a class action in any forum, arbitration or
              otherwise.
            </p>

            <h4>19. WAIVER AND SEVERABILITY OF TERMS.</h4>
            <p>
              The failure by Company to exercise or enforce any right or
              provision of the Agreement shall not constitute a waiver of such
              right or provision. If any provision of the Agreement is found by
              a court of competent jurisdiction to be invalid, the parties
              nevertheless agree that the court should endeavor to give effect
              to the parties&#39; intentions as reflected in the provision, and
              the other provisions of the Agreement remain in full force and
              effect.
            </p>

            <h4>20. FORCE MAJUERE.</h4>
            <p>
              Any delay in the performance of Company shall be excused when such
              delay in performance is due to any cause or event of any nature
              whatsoever beyond the reasonable control of Company, including,
              without limitation, any act of God; any fire, flood, or weather
              condition; any computer virus, worm, denial of service attack; any
              earthquake; and any act of a public enemy, war, pandemic,
              government-mandated lock down or quarantine, insurrection, riot,
              explosion or strike.
            </p>

            <h4>21. ENTIRE AGREEMENT.</h4>
            <p>
              This Agreement constitutes the entire agreement between you and
              Company and governs your use of the Website and Service,
              superseding any prior agreements. You also may be subject to
              additional terms and conditions that may apply when you use or
              purchase other Company services, affiliated services, third party
              content or third-party software.
            </p>

            <h4>22. STATUTE OF LIMITATIONS.</h4>
            <p>
              YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY,
              ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF RELATED TO USE OF THE
              WEBSITE SERVICES OR THIS AGREEMENT MUST BE FILED WITHIN ONE (1)
              YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER
              BARRED.
            </p>

            <h4>
              PLEASE PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS.
            </h4>

            <p>
              InstantCashOffers
              <br />
              ATTN: Customer Care
              <br />
              2041 Rosecrans Ave. Suite 3275
              <br />
              El Segundo, CA 90245
              <br />
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermOfUse;
