export const EXIT_PAGE_MESSAGE =
  'Are you sure you want to leave? There is so much more to explore!';

export const ZIP_CODE_ERROR_MESSAGE = 'Please enter a valid ZIP code';

export const NETWORK_ERROR = 'Network Response was not Ok';

export const FIRST_NAME_ERROR_MESSAGE = 'Please fill in your First Name';
export const LAST_NAME_ERROR_MESSAGE = 'Please fill in your Last Name';

export const ADDRESS_ERROR_MESSAGE = 'Please enter Street Address';
export const HOME_VALUE_ERROR_MESSAGE = 'Please enter Home Value';

export const ADDRESS_PATTERN_ERROR_MESSAGE =
  'Please do not put your email address in this field';

export const CITY_ERROR_MESSAGE = 'Please enter a city';

export const STATE_ERROR_MESSAGE = 'Please enter a State';

export const EMAIL_ERROR_MESSAGE = 'Please fill in your Email Address';
export const TCPA_ERROR_MESSAGE = 'Please enter a TCPA Text';

export const EMAIL_DOMAIN_ERROR_MESSAGE = 'Please verify your email domain';

export const PHONE_ERROR_MESSAGE =
  'Please fill in your Phone Number';

export const CONSECUTIVE_PHONE_NUMBER_ERROR_MESSAGE =
  'Please input a valid phone number';
