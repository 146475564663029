import {
  FIRST_NAME_ERROR_MESSAGE,
  LAST_NAME_ERROR_MESSAGE,
  EMAIL_ERROR_MESSAGE,
  NAME_REGEX,
} from '../../../lib/constants';
import CustomInput from '../../CustomComponents/CustomInput';
import { onlyLettersInput, validateEmail } from '../../../lib/utils';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext, usePrePopContext } from '../../../lib/contexts';
import { useRef } from 'react';
import CustomBackButton from '../../CustomComponents/CustomBackButton';

function FormStep10() {
  const { register, trigger, handleSubmit, formState, clearErrors } = useFormContext();
  const { updateFormData, formData, updateStep } = useFormDataContext();
  const { errors } = formState;
  const first_nameRef = useRef();
  const last_nameRef = useRef();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['firstname', 'lastname']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        firstname: first_nameRef,
        lastname: last_nameRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1); // Update step on radio select
    }
  });

  const handleInputChange = (data) => {
    updateFormData(data);
    const fieldName = Object.keys(data)[0];
    if (errors[fieldName]) {
      clearErrors(fieldName);
      if (fieldName === 'firstname') {
        first_nameRef.current?.blur();
      } else if (fieldName === 'lastname') {
        last_nameRef.current?.blur();
      }
      else if (fieldName === 'email') {
        last_nameRef.current?.blur();
      }
    }
  };

  return (
    <>
      <div id="question10">
        <div className="input-wrap">
          <label>First Name</label>
          <CustomInput
            type="text"
            name="firstname"
            register={register}
            ref={first_nameRef}
            onInput={onlyLettersInput}
            onInputChange={handleInputChange}
            value={formData.firstname}
            validation={{
              required: FIRST_NAME_ERROR_MESSAGE,
              pattern: {
                value: NAME_REGEX,
                message: 'Invalid first name format',
              },
            }}
          />
        </div>
        <div className="input-wrap">
          <label>Last Name</label>
          <CustomInput
            type="text"
            name="lastname"
            register={register}
            ref={last_nameRef}
            onInput={onlyLettersInput}
            onInputChange={handleInputChange}
            value={formData.lastname}
            validation={{
              required: LAST_NAME_ERROR_MESSAGE,
              pattern: {
                value: NAME_REGEX,
                message: 'Invalid last name format',
              },
            }}
          />
        </div>
        <div className="input-wrap">
          <label>Email Address</label>
          <CustomInput
            type="text"
            placeholder="Email Address"
            name="email"
            register={register}
            onInputChange={updateFormData}
            value={formData.email}
            validation={{
              required: EMAIL_ERROR_MESSAGE,
              validate: validateEmail,
            }}
          />
        </div>
        <button onClick={handleClickContinue} className="step-button">
          Next
        </button>
        <CustomBackButton/>
      </div>
    </>
  );
}

export default FormStep10;
